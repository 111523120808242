import Quill from "quill";

const BaseImageFormat = Quill.import("formats/image");

interface ImageValue {
  src: string;
  width?: string;
  height?: string;
}

export class CustomImageBlot extends BaseImageFormat {
  static blotName = "image";
  static tagName = "img";
  static className = "custom-image";

  static create(value: ImageValue | string): HTMLElement {
    const node = super.create(value) as HTMLElement;
    if (typeof value === "object") {
      node.setAttribute("src", value.src);
      if (value.width) {
        node.setAttribute("width", value.width);
      } else {
        node.removeAttribute("width");
      }
      if (value.height) {
        node.setAttribute("height", value.height);
      } else {
        node.removeAttribute("height");
      }
    } else {
      node.setAttribute("src", value);
      node.removeAttribute("width");
      node.removeAttribute("height");
    }
    return node;
  }

  static formats(domNode: HTMLElement): any {
    const formats = super.formats(domNode) || {};
    if (domNode.hasAttribute("width")) {
      formats.width = domNode.getAttribute("width");
    }
    if (domNode.hasAttribute("height")) {
      formats.height = domNode.getAttribute("height");
    }
    return formats;
  }

  format(name: string, value: any): void {
    if (name === "width" || name === "height") {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
