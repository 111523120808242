import { Quill } from "@vueup/vue-quill";

// Import Quill base classes
const Embed = Quill.import("blots/embed");

/// Define the TableOfContents blot
export class TableOfContentsBlot extends Embed {
  static blotName = "table-of-contents";
  static tagName = "div";
  static className = "table-of-contents";

  static create(value: any) {
    const node = super.create();
    node.setAttribute("contenteditable", "false");
    node.innerHTML = `<div class='flex flex-col '><p class='font-bold text-[16px]'>Table of Contents</p><div class='mt-1'><div class='list bg-light-default border rounded p-2 flex flex-col '></div></div></div>`; // Placeholder
    return node;
  }

  static value(domNode: any) {
    return {};
  }
}

Quill.register(TableOfContentsBlot);
