import { Quill } from "@vueup/vue-quill";

// Import Quill base classes
const Block = Quill.import("blots/block");

// Define the SectionHeader blot
export class SectionHeaderBlot extends Block {
  static blotName = "section-header";
  static tagName = "h2";
  static className = "section-header";

  static create(value: any) {
    const node = super.create();
    node.setAttribute("class", SectionHeaderBlot.className);
    return node;
  }
}
